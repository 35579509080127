html {
  box-sizing: border-box;
  height: 100%;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  -webkit-print-color-adjust: exact;
}
 
*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  background: #fff;
  font-family: 'Maven Pro', Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  height: 100%;
  min-height: 100vh;
}
 
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}