@mixin flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

@mixin button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}