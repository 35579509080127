@import 'config';
@import 'mixins';
@import 'animations';

.Header {
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  display: flex;
  height: $header-height-xs;
  justify-content: center;
  left: 0;
  padding: 0 20px;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;

  @media only screen and (min-width: $media-md) {
    height: $header-height-md;
    padding: 0 30px;
  }

  @media only screen and (min-width: $media-xl) {
    height: $header-height-xl;
    padding: 0 40px;
    z-index: 1;
  }

  &__wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    max-width: $container-max-width;
    width: 100%;
  }

  &__logo {
    @include flex-center;
    fill: $navy;
    height: 35px;
    pointer-events: all;
    transition: fill $hover-transition;

    @media only screen and (min-width: $media-xl) {
      height: 40px;
    }
  }

  &__svg {
    height: 100%;
  }

  &__menu {
    @include flex-center;
    display: flex;
  }

  &__link {
    font-size: 14px;
    pointer-events: all;
    text-decoration: none;
    background-color: initial;
    border: 1px solid #ff364a;
    color: #ff364a;
    align-items: center;
    border-radius: 8px;
    display: inline-flex;
    font-weight: 500;
    justify-content: center;
    padding: 5px 16px;
    transition: 0.2s;

    &:hover {
      background-color: #ff364a;
      color: #fff;
    }
  }

  &__burger {
    @include button;
    fill: $blue;
    pointer-events: all;
    width: 18px;
    // hide burger button temporarily since there's only one menu item
    display: none;

    &--active {
      fill: $blue;
    }

    // @media only screen and (min-width: $media-lg) {
    //   display: none;
    // }
  }
}
