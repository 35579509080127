@keyframes scale {
  0% {
    transform: rotateX(0) scale(1);
  }

  50% {
    transform: rotateX(12deg) scale(0.95);
  }

  100% {
    transform: rotateX(0) scale(1);
  }
}
