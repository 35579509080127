@import "config";
@import "mixins";
@import "animations";

.App {
  background-color: $ice-blue;
  min-height: 100vh;
  justify-content: center;
  padding: $offset-xs;
  padding-top: $offset-xs + $header-height-xs;
  width: 100%;
  display: flex;
  flex-wrap: wrap;


  &__grid {
    align-content: center;
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr;
    max-width: $container-max-width;
    width: 100%;
    padding-top: 10px;
  }

  &__welcome {
    text-align: center;
    line-height: 1.5rem;
    padding-bottom: 10px;
    width: 100%;

    a {
      color: $blue;
    }
  }

  &__title {
    text-align: center;
    line-height: 2.5rem;
    padding-bottom: 10px;
    width: 100%;
  }

  @media only screen and (min-width: $media-sm) {
    padding: $offset-sm;
    padding-top: $offset-sm + $header-height-sm;
  }

  @media only screen and (min-width: $media-md) {
    padding: $offset-md;
    padding-top: $offset-md + $header-height-md;

    &__grid {
      gap: 20px;
    }
  }

  @media only screen and (min-width: $media-lg) {
    padding: $offset-lg;
    padding-top: $offset-lg + $header-height-lg;

    &__grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media only screen and (min-width: $media-xl) {
    padding: $offset-xl;
    padding-top: $offset-xl;

    &__grid {
      gap: 50px;
    }
  }
}
