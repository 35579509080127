// Media
$media-xs: 320px;
$media-sm: 480px;
$media-md: 768px;
$media-lg: 1024px;
$media-xl: 1200px;

// Colors
$blue: #0099f9;
$navy: #15354a;
$ice: #a6d5e0;
$ice-blue: #eff6ff;
$light-blue: #7ec3ed;
$light-black: #0e2434;
$yellow: #fcd73d;
$rose: #ff364a;
$purple: #770f7c;
$gray: #6e757b;
$gray-light: #8a8e91;
$ash: #b3b8ba;

$gray-dark: #525252;
$ash-light: #e6eaec;
$blue-background: #cbd0d4;

$menu-transition: 0.3s;
$hover-transition: 0.15s;
$demo-transition: 0.3s;
$card-hover: 0.2s;

$container-max-width: 1300px;

$offset-xs: 30px;
$offset-sm: 50px;
$offset-md: 70px;
$offset-lg: 100px;
$offset-xl: 150px;

$header-height-xs: 45px;
$header-height-sm: 55px;
$header-height-md: 60px;
$header-height-lg: 70px;
$header-height-xl: 80px;
