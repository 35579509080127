@import 'config';
@import 'mixins';
@import 'animations';

.Card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15), 0 2px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;
  transition: box-shadow $card-hover;
  will-change: opacity;

  &__wrapper {
    height: 100%;
    a {
      text-decoration: none;
    }
  }

  &__preview {
    @include flex-center;
    background-color: #fff;
    overflow: hidden;
    padding: 20px 20px 40px 20px;
    position: relative;
    opacity: 0.85;

    &::before {
      background-color: $navy;
      content: '';
      height: 100%;
      left: 0;
      mix-blend-mode: multiply;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: opacity $card-hover ease-out;
      width: 100%;
      z-index: 1;
    }
  }

  &__image {
    border-radius: 5px;
    box-shadow: 0 1px 20px rgba(0, 157, 255, 0.15),
      0 1px 5px rgba(0, 157, 255, 0.15);
    border: 1px solid #fff;
    filter: drop-shadow(0px 15px 39px rgba(0, 157, 255, 0.15));
    border-radius: 7px;
    opacity: 0.8;
    transition: transform $card-hover ease-out;
    width: 100%;
  }

  &__description {
    color: #6e757b;
    padding-top: 20px;
    line-height: 1.4rem;

    a {
      color: $blue;
    }
  }

  &__header {
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 30px 20px;
  }

  &__effort {
    color: #6e757b;
    padding-bottom: 10px;
    text-transform: uppercase;
  }

  &__title {
    border-bottom: 1px solid $blue-background;
    color: $light-black;
    font-size: 1rem;
    font-weight: 700;
    padding-bottom: 0.5rem;
    position: relative;
    transition: color $card-hover;

    &::after {
      border-bottom: 1px solid $gray;
      bottom: -1px;
      content: '';
      left: 0;
      position: absolute;
      transition: width 1s cubic-bezier(0.06, 0.62, 0.44, 1);
      width: 0;
    }
  }

  &:hover {
    box-shadow: 0px 15px 39px rgba(0, 157, 255, 0.15);

    .Card__preview {
      opacity: 1;
    }

    .Card__image {
      box-shadow: 0 1px 20px rgb(0, 80, 130, 0.2),
      0 1px 5px rgb(0, 80, 130, 0.2);
    }

    .Card__title {
      &::after {
        width: 100%;
      }
    }
  }
}
